<template>
    <div class="chat-img">
        <audio ref="sendAudio" @ended="overAudio"></audio>
        <el-image v-if="type == 2" style="max-width: 300px;" :src="cn" fit="fill">
            <div slot="placeholder" class="image-slot">
                <i class="el-icon-loading"></i>
            </div>
        </el-image>
        <div class="spinner" v-if="type == 3" @click="playAudio(cn)" :class="{'spinnering' :audioPlayIng,'other':position=='left'}">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
            <div class="rect6"></div>
            <div class="rect7"></div>
        </div>
        <video v-if="type == 4" width="320" height="240" controls>
            <source :src="cn" type="video/mp4">
            <source :src="cn" type="video/ogg">
            您的浏览器不支持 HTML5 video 标签。
        </video>
    </div>
</template>
<script>
import { fileURL } from "@/api/config.js"
export default {
    props: {
        // 1文字，2图片，3语言，4视频，5名片
        type: {
            type: Number | String,
        },
        cn: {
            type: String,
        },
        position: {
            type: String,
        }
    },
    data() {
        return {
            fileURL,
            audioPlayIng: false
        }
    },
    methods: {
        playAudio(url) {
            this.$refs.sendAudio.src = url
            if (!this.audioPlayIng) {
                this.$refs.sendAudio.play()
            } else {
                this.$refs.sendAudio.pause()
            }
            this.audioPlayIng = !this.audioPlayIng
        },
        // 音频播放完毕
        overAudio() {
            this.audioPlayIng = false
        }
    }
}
</script>
<style scoped lang="scss">
.chat-img {
    display: flex;
    align-items: center;

    .spinner {
        width: 60px;
        height: 15px;
        text-align: center;
        cursor: pointer;
    }

    .spinner>div {
        background-color: #fff;
        height: 100%;
        width: 2px;
        margin: auto 2px;
        display: inline-block;
        -webkit-transform: scaleY(0.4);

    }
    .other.spinner>div{
        background-color: rgb(64, 158, 255);
    }

    .spinner .rect3,
    .spinner .rect5 {
        -webkit-transform: scaleY(0.8);
    }

    .spinner .rect4 {
        -webkit-transform: scaleY(1);
    }

    .spinnering>div {
        -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
        animation: stretchdelay 1.2s infinite ease-in-out;
    }

    .spinnering .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinnering .rect3 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .spinnering .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinnering .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    .spinnering .rect6 {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }

    .spinnering .rect6 {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }

    @-webkit-keyframes stretchdelay {

        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
        }

        20% {
            -webkit-transform: scaleY(1);
        }
    }

    @keyframes stretchdelay {

        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }

        20% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
        }
    }

}
</style>

<template>
    <div class="chartItem">
        <img :src="getImageSrc(info.avatarUrl)" alt="">
        <div class="rightInfo">
            <p class="name">{{ info.nickname }}</p>
            <div class="chartContent">
                <div v-if="![2, 3, 4].includes(type)">
                    <div class="en">
                        {{ en }}
                    </div>
                    <div class="line" v-if="cn"></div>
                    <div class="cn" v-if="cn">
                        {{ cn }}
                    </div>
                </div>
                <other-file-type :type="type" :cn="en" position="left"></other-file-type>
            </div>
        </div>
    </div>
</template>
<script>
import { fileURL } from "@/api/config.js"
import otherFileType from './otherFileType/index.vue'
export default {
    components: { otherFileType },
    props: {
        userName: {
            type: Number,
        },
        en: {
            type: String,

        },
        cn: {
            type: String,
        },
        info: {
            type: Object | String
        },
        // 1文字，2图片，3语言，4视频，5名片
        type: {
            type: Number | String,
        },
    },
    data() {
        return {
            fileURL
        }
    },
  methods:{
    getImageSrc(avatarUrl) {
      if (!avatarUrl){
        avatarUrl = '/avatar/default.jpeg'
      }
      if (avatarUrl.charAt(0) === '/') {
        return fileURL+avatarUrl;
      } else {
        return avatarUrl;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.chartItem {
    margin-top: 20px;
    width: 90%;
    display: flex;

    .rightInfo {
        flex: 1;
        word-wrap: break-word;
        max-width: 60%;

        .name {
            font-weight: bold;
            text-align: left;
        }

        .chartContent {
            color: rgb(153, 153, 153);
            margin-top: 10px;
            padding: 10px;
            box-sizing: border-box;
            border: 1px solid rgb(121, 121, 121);
            border-radius: 6px;
            text-align: left;
            width: max-content;
            max-width: 400px;

            .line {
                height: 2px;
                margin: 10px 0px;
                width: 100%;
                background-color: rgb(153, 153, 153);
            }
        }
    }

    img {
        width: 64px;
        height: 64px;
        margin-right: 20px;
        flex-shrink: 0;
    }

}
</style>

<template>
    <div class="menuItem">
        <i :class="icon" style="margin-right: 10px;font-size:18px;font-weight: bold;"></i> {{ name }}
    </div>

</template>


<script>
export default{
    props:{
        name:{
            type:String
        },
        icon:{
            type:String
        },
    }
}
</script>
<style lang="scss" scoped>
.menuItem{
    height: 50px;
    color: rgb(204,204,204);
    line-height: 50px;
    cursor: pointer;
    font-weight: bold;
    &:hover{
        background-color: rgb(38,41,51);
    }
}
</style>



import axios from "axios";
import { baseURL } from './config'
import { Message } from 'element-ui'
import router from '../router/index'

const service = axios.create({
    baseURL: baseURL,
    timeout: 6000 * 10, // request timeout
    // withCredentials: true, // send cookies when cross-domain requests
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        config.headers.Authorization = localStorage.getItem("token")
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (res) => {
        if (res.data && res.data.code == 200) {
            return res.data;
        } else {
            if(res.data.code == 401){
                window.localStorage.clear()
                router.push('/login')
                return
            }
            if (res.config.notIntercept){return res.data}
            Message({ message: res.data.message, type: 'error' });
        }
    },
    (error) => {
        //提示 todo
        return Promise.reject(error);
    }
);

export default service;

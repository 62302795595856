<template>
    <div class="chartContent">
        <audio ref="audio" src="../../assets/interface-124464.mp3"></audio>
        <audio ref="sendAudio" src="../../assets/send.mp3"></audio>
        <div class="Logo">
            <span>迷夜Whatsapp拓客系统</span>
            <el-button type="primary" @click="loyout">退出登录</el-button>
        </div>
        <div class="content">
            <div class="menu">
                <MenuItem v-for="(item, index) in menuArr" :icon="item.icon" :name="item.name"
                    @click.native="showDialog(index)" />
                <div class="play-witch">
                    <span>消息音效</span>
                    <el-switch v-model="isPlay" @change="isPlayChange"></el-switch>
                </div>
            </div>
            <UserList ref="userListRef" :channelId="currChannelId" @changeContent="changeContent" @getReport="getReport" />
            <div class="chart" v-loading="pageLoading">
                <div class="head">
                    <div v-if="me" class="chat-info">
                        <span v-if="me">{{ me }}正在和好友{{ other }}聊天</span>
                        <div class="right-align">
                            <el-tag type="success" v-if="accountInfo.status == 1" size="mini"
                                style="margin-left: 10px;">在线</el-tag>
                            <el-tag type="danger" v-if="accountInfo.status == 2" size="mini"
                                style="margin-left: 10px;">封号</el-tag>
                            <el-tag type="warning" v-if="accountInfo.status == 3" size="mini"
                                style="margin-left: 10px;">重连中..</el-tag>
                            <el-tag type="warning" v-if="accountInfo.status == 4" size="mini"
                                    style="margin-left: 10px;">掉登录</el-tag>
                        </div>
                    </div>
                    <span v-else>暂无会话</span>
                </div>
                <div class="chartList" ref="chartList">
                    <el-button type="text" @click="loadMore" v-show="isMore">查看更多</el-button>
                    <div class="message" v-for="(item, index) in messageList" :key="index">
                        <OtherChart :info="friendInfo" v-if="!item.from_uid.includes('user')"
                            :cn="formatTarget(item.payload)" :en="formatOrigin(item.payload)" :type="item.type" />
                        <MyChart :info="accountInfo" v-else :en="formatTarget(item.payload)"
                            :cn="formatOrigin(item.payload)" :stateCode="item.stateCode" :sendState="item.sendState"
                            :type="item.type" />
                    </div>
                </div>
                <!-- @keyup.enter="sendMessage" -->
                <div class="textarea" v-loading="loading" v-show="messageListForm.channel_id">
                    <div class="textHead" v-if="accountInfo.status !== 2">
                        <div class="left">
                            <el-popover ref="popoverRef" :offset="10" :visible-arrow="true" placement="top-start"
                                width="400" trigger="click" class="emoBox">
                                <div class="emotionList">
                                    <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item, index) in faceList"
                                        :key="index" class="emotionItem">{{ item }}</a>
                                </div>
                                <img slot="reference" style="margin-top: 2px;vertical-align: middle;" class="kj-icon"
                                    src="../../assets/xiaolian.png" alt="">
                            </el-popover>
                            <upload-file :value.sync="imgValue" @change="imgValueChange" @openLoading="loading = true">
                                <i class="el-icon-picture-outline kj-icon"></i>
                            </upload-file>
                            <upload-file :value.sync="audioValue" accept=".mp3" @change="audioValueChange"
                                @openLoading="loading = true">
                                <i class="el-icon-microphone kj-icon"></i>
                            </upload-file>
                            <upload-file :value.sync="videoValue" accept=".mp4,.ogg" @change="videoValueChange"
                                @openLoading="loading = true">
                                <i class="el-icon-video-camera kj-icon"></i>
                            </upload-file>
                            <!-- <i class="el-icon-phone-outline kj-icon"></i>
                            <i class="el-icon-video-camera kj-icon"></i> -->
                        </div>
                        <div class="right">
                            <el-button size="small" type="success" @click.stop="queue()">Enter发送，Enter+Shift换行</el-button>
                        </div>
                    </div>
                    <div class="textContent">
                        <!-- <textarea id='textarea' class="acc-ban" :disabled="accountInfo.status !== 1"
                            :placeholder="accountInfo.status !== 1 ? '账号状态异常,无法发送' : ''" ref="sendTextRef"
                            v-model="sendText" name="" cols="30" rows="8" @keydown="handleKeyCode($event)"></textarea> -->
                        <quill-editor class="editor" :disabled="accountInfo.status !== 1" ref="myQuillEditor"
                            :options="editorOption" v-model.lazy.trim="sendText" @change="onEditorChange($event)"
                            @focus="onEditorFocus($event)" />
                        <div v-if="accountInfo.status !== 1" class="acc-ban">账号状态异常,无法发送</div>
                    </div>
                </div>
            </div>
            <RightMenu ref="rightMenuRef" v-if="accountInfo.accountId" @setLanguage="setLanguage"
                @shortcut="shortcutSize" />
        </div>
        <el-dialog :close-on-click-modal="false" @close="getFriendList()" title="导入好友" :visible.sync="showImport">
            <el-form :model="importForm" ref="importRuleForm" label-width="100px">
                <el-form-item label="选择账号：" prop="accountId">
                    <el-select style="width: 100%;" multiple v-model="importForm.accountId" placeholder="请选择">
                        <el-option v-for="item in accountSelectList" :key="item.accountId" :label="item.accountName"
                            :value="item.accountId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="导入账号：">
                    <el-upload class="upLoad" action="" :before-upload="handleFileChange" :multiple="false" :limit="1"
                        :file-list="fileList">
                        <el-button icon="el-icon-upload">选择文件</el-button>
                        <div slot="tip" class="el-upload__tip">上传txt文件，单个文件不能超过10MB</div>
                    </el-upload>
                    <el-input type="textarea" placeholder="账号" :rows="12" v-model="importForm.friends">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showImport = false">取 消</el-button>
                <el-button type="primary" @click="importSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="新增好友" :visible.sync="showAdd">
            <el-form :model="addForm" :rules="rule1" ref="ruleForm" label-width="100px">
                <el-form-item label="任务名称：" prop="name">
                    <el-input v-model="addForm.name"></el-input>
                </el-form-item>
                <el-form-item label="选择账号：" prop="name">
                    <Select value="1111" />
                </el-form-item>
                <el-form-item label="通道类型：" prop="name" align="left">
                    <el-radio v-model="radio" label="1">通讯录</el-radio>
                    <el-radio v-model="radio" label="2">链接</el-radio>
                </el-form-item>
                <el-form-item label="导入账号：" prop="name">
                    <el-upload class="upLoad" action="https://jsonplaceholder.typicode.com/posts/" :file-list="fileList">
                        <el-button icon="el-icon-upload">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">上传txt文件，单个文件不能超过10MB</div>
                    </el-upload>
                    <el-input type="textarea" placeholder="请输入内容" v-model="textarea">
                    </el-input>
                </el-form-item>
                <el-form-item label="拨打电话：" align="left">
                    <el-radio v-model="radio" label="1">关闭</el-radio>
                    <el-radio v-model="radio" label="2">开启</el-radio>
                </el-form-item>
                <el-form-item label="拨打视频：" align="left">
                    <el-radio v-model="radio" label="1">关闭</el-radio>
                    <el-radio v-model="radio" label="2">开启</el-radio>
                </el-form-item>
                <el-form-item label="拨打时长：" align="left">
                    <el-radio v-model="radio" label="1">默认（1-3s）</el-radio>
                    <el-radio v-model="radio" label="2">20-25s</el-radio>
                </el-form-item>
                <el-form-item align="left">
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="好友转移" :visible.sync="showTransfer">
            <el-form :model="moveForm" ref="moveRuleForm" label-width="100px">
                <el-form-item label="转移账号：" prop="accountId">
                    <el-select style="width: 100%;" v-model="moveForm.accountId" placeholder="请选择">
                        <el-option v-for="item in accountSelectList" :key="item.accountId" :label="item.accountName"
                            :value="item.accountId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="接收账号：" prop="toAccountId">
                    <el-select style="width: 100%;" v-model="moveForm.toAccountId" placeholder="请选择">
                        <el-option v-for="item in accountSelectList" :key="item.accountId" :label="item.accountName"
                            :value="item.accountId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showTransfer = false">取 消</el-button>
                <el-button type="primary" @click="moveSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="账号注册" :visible.sync="showRegister" width="800px">
            <register @registerEnd="registerEnd" />
        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="扫码网页" :visible.sync="showScanWeb" width="800px">
            <scan-web @registerEnd="registerEnd" />
        </el-dialog>



    </div>
</template>
<script>
import ScanWeb from "@/views/register/scanWeb.vue";

const appData = require("../../assets/json/emoji.json");
import MyChart from "./components/MyChart.vue"
import UserList from "./components/UserList.vue"
import MenuItem from "./components/MenuItem.vue"
import RightMenu from "./components/RightMenu.vue"
import OtherChart from "./components/OtherChart.vue"
import Select from "./components/Select.vue"
import uploadFile from "./components/upload-file/index.vue"
import register from '../register/index.vue'
import {
    login,
    getMessageList,
    translation,
    accountAccountList,
    friendMove,
    friendAdd,
    messageSend,
    getFriendList,
} from "@/api/request/api.js"
import WKSDK from "wukongimjssdk"
import { MessageText, Channel } from "wukongimjssdk"
import store from "@/store/index.js"
import { encode, decode } from 'js-base64';

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
//这个是插件优化的样式，在下面贴出来
// import "@/config/quill/quill.scss";
export default {
    created() {
        // console.log(encode("你好"),decode(encode("你好")))
    },
    components: {
      ScanWeb,
        MyChart,
        OtherChart,
        UserList,
        MenuItem,
        RightMenu,
        Select,
        register,
        uploadFile,
        quillEditor
    },
    data() {
        return {
            isMore: false,//聊天记录查看更多
            isLogin: false,
            radio: "",
            textarea: "",
            fileList: [],
            userOptions: [
                {

                }
            ],
            addForm: {
                name: "111"
            },
            importForm: {
                accountId: [],
                friends: ''
            },
            moveForm: {
                accountId: '',
                toAccountId: ''
            },
            showScanWeb:false,
            showImport: false,
            showAdd: false,
            showTransfer: false,
            showRegister: false,
            sendText: "",
            menuArr: [
                {
                    icon: 'el-icon-upload2',
                    name: "导入好友"
                },
                // {
                //     icon: 'el-icon-circle-plus-outline',
                //     name: "新增好友"
                // },
                {
                    icon: 'el-icon-sort',
                    name: "好友转移"
                },
                // {
                //     icon: 'el-icon-user',
                //     name: "接码注册"
                // },
                {
                  icon: 'el-icon-monitor',
                  name: "扫码上号"
                }
            ],
            rule1: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                ],
            },
            me: "",
            other: "",
            messageListForm: {
                channel_id: "", //频道ID
                start_message_seq: 0, //开始消息列号（结果包含start_message_seq的消息）
                end_message_seq: 0, //结束消息列号（结果不包含end_message_seq的消息）
                limit: 15, //消息数量限制
                pull_mode: 1 //拉取模式 0:向下拉取 1:向上拉取
            },
            messageList: [],
            translationText: "",
            currChannelId: '', // 当前选中的好友id
            currLanguage: '', //语言
            loading: false,
            messageUserInfo: [],
            friendInfo: {},
            accountInfo: {},
            isPlay: false,
            accountSelectList: [],
            pageLoading: false,
            faceList: [],
            imgValue: [], // 图片地址
            audioValue: [],
            videoValue: [],
            //富文本配置
            editorOption: {
                placeholder: "",
                modules: {
                    keyboard: {
                        bindings: {
                            shift_enter: {
                                key: 13,
                                shiftKey: true,
                                handler: (range, ctx) => {
                                    console.log(range, ctx); // if you want to see the output of the binding
                                    this.editor.insertText(range.index, '\n');
                                }
                            },
                            enter: {
                                key: 13,
                                handler: () => { // submit form }
                                    this.queue();
                                }
                            }
                        }

                    }
                }

            },
            messageType: 1,
            textFouceIndex: 1, // 记录光标的位置，方便插入图片/视频...
        }
    },
    computed: {

    },
    mounted() {
        /*防止多个链接出现,刷新浏览器清理链接和监听*/
        window.onbeforeunload = () => {
            this.clear()
        };
        for (let i in appData) {
            this.faceList.push(appData[i].char);
        }
        this.isPlay = (localStorage.getItem("isPlay") == 'true' ? true : false)
        let chatToken = localStorage.getItem("token")
        let chatWsAddr = localStorage.getItem("chatWsAddr")
        let chatUid = localStorage.getItem("chatUid")
        if (chatToken) {
            console.log("开始连接...")
            this.initSocket(chatWsAddr, chatUid, chatToken);
        } else {
            this.logout()
        }

    },
    /*移除监听*/
    beforeDestroy() {
        this.clear()
    },
    methods: {
        getFriendList,
        // 获取账号下拉数据
        getAccount() {
            accountAccountList().then(res => {
                if (res && res.code === 200) {
                    this.accountSelectList = res.data
                }
            })
        },
        loadMore() {
            this.getMessageList("loadmore");
        },
        formatOrigin(value) {
            return JSON.parse(decode(value)).content.content
        },
        formatTarget(value) {
            return JSON.parse(decode(value)).content.translation
        },
        clear() {
            console.log("移除监听...")
            WKSDK.shared().connectManager.removeConnectStatusListener(this.connectStatusListener)
            console.log("断开连接...")
            WKSDK.shared().disconnect()
        },
        logout() {
            window.localStorage.clear()
            this.$router.push('/login')
        },

        // 获取聊天记录
        async getMessageList(model) {
            if (model !== "loadmore") { //不是查看更多,则将消息ID设为0
                this.messageListForm.start_message_seq = 0
            }
            this.pageLoading = true
            const result = await getMessageList(this.messageListForm);
            // this.$refs.sendTextRef.focus();
            this.pageLoading = false
            if (result.code === 200) {
                // console.log(result.data)
                if (!result.data) {
                    console.log('没有会话消息');
                    return
                }
                this.isMore = result.data.more
                if (result.data.messages.length > 0) {
                    this.messageListForm.start_message_seq = result.data.messages[0].message_seq - this.messageListForm.limit
                }
                this.accountInfo = result.data.accountInfo
                // console.log(this.accountInfo)
                this.friendInfo = result.data.friendInfo
                localStorage.setItem("accountId", this.accountInfo.accountId)
                if (model === "loadmore") {
                    result.data.messages.reverse().forEach(v => {
                        const content = JSON.parse(decode(v.payload)).content
                        this.messageList.unshift({
                            ackId: content.ackId,
                            from_uid: v.from_uid,
                            stateCode: content.stateCode,
                            sendState: content.sendState,
                            payload: v.payload,
                            type: content.type,
                        })
                    })
                    // this.$nextTick(() => {
                    //     this.$refs.chartList.scrollTop = this.$refs.chartList.scrollHeight / (this.messageList.length / 20)
                    // })
                } else {
                    this.messageList = []
                    result.data.messages.forEach(v => {
                        const content = JSON.parse(decode(v.payload)).content
                        console.log(content)
                        this.messageList.push({
                            ackId: content.ackId,
                            from_uid: v.from_uid,
                            stateCode: content.stateCode,
                            sendState: content.sendState,
                            type: content.type,
                            payload: v.payload,
                        })
                    })
                    this.$nextTick(() => {
                        this.scrollToBottom();
                    })
                }
                this.$nextTick(() => {
                    this.getReport()
                })
            } else {
                this.$message.error(result.message)
            }

        },
        scrollToBottom() {
            this.$nextTick(() => {
                try {
                    this.$refs.chartList.scrollTop = this.$refs.chartList.scrollHeight
                } catch (e) { }

            })
        },
        async changeContent(info) {
            // this.currChannelId = info.value.channelId
            // this.messageList = []
            this.sendText = ''
            if (info.model === "friends") {
                store.commit("SET_FRIENDINFO", info.value)
                this.me = `${this.accountInfo.nickname}(${this.accountInfo.phoneNumber})`
                this.other = `${info.value.nickname}(${info.value.phoneNumber})`
                this.messageListForm.channel_id = info.value.channelId
            } else {
                this.me = `${info.value.accountInfo.nickname}(${info.value.accountInfo.phoneNumber})`
                this.other = `${info.value.friendInfo.nickname}(${info.value.friendInfo.phoneNumber})`
                this.messageListForm.channel_id = info.value.channel_id;
            }
            this.getMessageList();
        },
        // 文本域回车
        handleKeyCode(event) {
            if (!event.shiftKey && event.keyCode == 13) {
                if (!event.metaKey) {
                    event.preventDefault();
                    this.queue();
                } else {
                    this.sendText = this.sendText + '\n';
                }
            }
        },
        // 消息队列
        queue() {
            let imgurls = []
            let videourls = []
            let audiourls = []
            console.log(this.sendText)
            // 获取每个img url
            try {
                let imgStrs = this.sendText.match(/<img.*?>/g)
                imgurls = imgStrs.map(url => {
                    if (!url.match(/\ssrc=['"](.*?)['"]/)[1].includes('?语音=')) {
                        return url.match(/\ssrc=['"](.*?)['"]/)[1]
                    }
                })
                console.log(imgurls)
            } catch (error) {

            }
            try {
                let videoStrs = this.sendText.match(/<iframe.*?>/g)
                videourls = videoStrs.map(url => {
                    return url.match(/\ssrc=['"](.*?)['"]/)[1]
                })
                console.log(videourls)
            } catch (error) {

            }
            try {
                let audioStrs = this.sendText.match(/<img.*?>/g)
                audiourls = audioStrs.map(url => {
                    if (url.match(/\ssrc=['"](.*?)['"]/)[1].includes('?语音=')) {
                        return url.match(/\ssrc=['"](.*?)['"]/)[1].split('=')[1]
                    }
                })

            } catch (error) {

            }
            if (this.fixUpText()) {
                this.messageType = 1
                this.sendMessage()
            }
            if (imgurls && imgurls.length > 0) {
                this.messageType = 2
                imgurls.forEach(val => {
                    val && this.sendMessage(val)
                })
            }
            if (audiourls && audiourls.length > 0) {
                this.messageType = 3
                audiourls.forEach(val => {
                    val && this.sendMessage(val)
                })
            }
            if (videourls && videourls.length > 0) {
                this.messageType = 4
                videourls.forEach(val => {
                    val && this.sendMessage(val)
                })
            }

        },
        // 取出纯文本
        fixUpText() {
            return this.sendText.replace(/<p.*?>|<\/p>/ig, "").replace(/<img.*?>|<\/>/ig, "").replace(/<iframe.*?>|<\/iframe>/ig, "").replace(/<br.*?>|\>/ig, "").replace(/<a.*?>|<\/a>/ig, "")
        },
        // 处理消息发送逻辑
        async sendMessage(val) {
            let tok = localStorage.getItem("token")
            if (!tok) {
                this.loyout()
                return
            }
            /*空消息处理*/
            if (![2, 3, 4].includes(this.messageType) && this.sendText.trim() === "") { return }

            /*消息类型说明 type 1=文字 2=图片 3=语音 4=视频 5=名片*/
            let Obj = {}
            switch (this.messageType) {
                case 1://文字消息
                case 5://名片消息
                    Obj = {
                        content: this.fixUpText(),
                    }
                    break
                case 2://图片消息
                    Obj = {
                        content: val,
                    }
                    break
                case 3: //语音
                    Obj = {
                        content: val,//todo 语音的播放地址 随便找一个调试
                    }
                    break
                case 4://视频
                    Obj = {
                        // content: {
                        //     videoPath: 'https://chat.miye009.com/upload_file/quick/11/cv93v5oanchc6pekp4.png',//todo 视频地址 随便找一个调试
                        //     imagePath: this.videoValue,//todo 视频封面图片地址,随便找一个调试
                        // },
                        content: val
                    }
                    break
            }
            Obj.type = this.messageType
            Obj.tragetLanguage = this.currLanguage ? this.currLanguage : 'en'
            Obj.channel_id = this.messageListForm.channel_id
            this.loading = true
            messageSend(Obj).then(res => {
                this.sendText = ''
                this.imgValue = []
                this.audioValue = []
                this.videoValue = []
            }).finally(() => {
                this.loading = false
            })
        },
        initSocket(addr, uid, token) {
            // 单机模式可以直接设置地址
            WKSDK.shared().config.addr = addr; // 默认端口为5200
            // 认证信息
            WKSDK.shared().config.uid = uid; // 用户uid（需要在悟空通讯端注册过）
            WKSDK.shared().config.token = token; // 用户token （需要在悟空通讯端注册过）
            WKSDK.shared().connectManager.connect();

            // 连接状态监听
            console.log("开始监听...")
            WKSDK.shared().connectManager.addConnectStatusListener(this.connectStatusListener);
            /*取消监听消息发送状态*/
            // WKSDK.shared().chatManager.addMessageStatusListener((packet) => {
            //     if (packet.reasonCode === 1) {
            //         // 发送成功
            //         console.log("发送成功")
            //         this.sendText = ""
            //         setTimeout(() => {
            //             this.scrollToBottom();
            //         }, 100)
            //
            //     } else {
            //         // 发送失败
            //         console.log("发送失败")
            //     }
            // });
            //  监听消息

            /*接收消息*/
            WKSDK.shared().chatManager.addMessageListener((message) => {
                console.log(message)
                if (message.fromUID === "system") {
                    this.handlerSystem(message.content.contentObj)
                    return;
                }

                if (this.isPlay && !message.fromUID.includes('user')) {
                    this.$refs.audio.play()
                }
                if (message.channel.channelID != this.messageListForm.channel_id) {
                    this.currChannelId = message.channel.channelID
                    this.$nextTick(() => {
                        this.$refs.userListRef.setUnreads(this.currChannelId)
                        // 收到新消息,如果会话列表没有则判断from_uid是chat开头,说明有新好友了,
                        // 这个消息需要提示出来,并且需要知道有新好友增加了,
                        // 更新会话列表/更新上粉统计, 确保消息计数准确,并弹Notification 通知
                        if (message.fromUID.includes('chat')) {
                            this.$notify({
                                dangerouslyUseHTMLString: true,
                                duration: 3000, // 设置为 0 表示不自动关闭
                                type: 'success', // 将 type 改为 'warning' 表示警告类型
                                title: '提示:',
                                message: `<strong>新粉丝提醒</strong>`
                            });
                            // 更新统计信息
                            this.getReport()
                            // 更新会话列表
                            this.$refs.userListRef.getConversation()
                        }
                    })
                    return
                }
                this.messageList.push({
                    ackId: message.content.text.ackId,
                    source: message.status + 1,
                    from_uid: message.fromUID,
                    stateCode: message.content.text.stateCode,
                    sendState: message.content.text.sendState,
                    type: message.content.text.type,
                    payload: encode(JSON.stringify({ content: message.content.text }))
                })
                this.$nextTick(() => {
                    this.scrollToBottom();
                })
            });
            // 消息发送状态监听
            // 消息监听
            // class TextMessage extends MessageContent {
            //     // width!: number // gif宽度
            //     // height!: number // gif高度
            //     // url!: string // gif远程下载地址
            //     // 解码
            //     decodeJSON(content) {
            //         this.type = content["type"]
            //         this.content = content["content"]
            //         this.translation = content["translation"]
            //     }
            //     // 编码
            //     encodeJSON() {
            //         return { "type": this.type, "content": this.content, "translation": this.translation }
            //     }

            // }
            // WKSDK.shared().register(() => new TextMessage());
            // 更多配置,查看：
            // WKSDK.shared().config;

        },

        connectStatusListener(status, reasonCode) {
            if (reasonCode === 2) {
                this.logout()
            }
        },

        handlerSystem(contentObj) {
            console.log("handlerSystem contentObj=", contentObj)
            const event = contentObj.event ?? contentObj.event.toString()

            switch (event) {
                case "accountStatus":
                    this.$refs.userListRef.getFriendList()
                    this.$refs.userListRef.getConversation()
                    const accountId = contentObj.accountId
                    const status = contentObj.status
                    const phoneNumber = contentObj.phoneNumber
                    if (accountId === this.accountInfo.accountId) {
                        this.accountInfo.status = status
                    }


                    switch (status) {
                        case 2:
                            this.$refs.audio.play()
                            this.$notify({
                                dangerouslyUseHTMLString: true,
                                duration: 0, // 设置为 0 表示不自动关闭
                                type: 'error', // 将 type 改为 'warning' 表示警告类型
                                title: '封号提示',
                                message: `<strong>号码:${phoneNumber}已封号!</strong>`
                            });
                            break
                        // case 1: //上线
                        //     break
                        //
                        // case 3://离线
                        //     break
                        case 4://掉登
                          this.$refs.audio.play()
                          this.$notify({
                            dangerouslyUseHTMLString: true,
                            duration: 0, // 设置为 0 表示不自动关闭
                            type: 'error', // 将 type 改为 'warning' 表示警告类型
                            title: '掉登提示',
                            message: `<strong>号码:${phoneNumber}已掉登!</strong>`
                          });
                          break
                    }
                    break
                case "messageState":
                    const channelId = contentObj.channelId
                    if (channelId === this.messageListForm.channel_id) {
                        const foundValue = this.messageList.find(value => value.ackId === contentObj.ackId);
                        if (foundValue) {
                            if (this.isPlay) {
                                this.$refs.sendAudio.play()
                            }
                            foundValue.sendState = contentObj.sendState
                            foundValue.stateCode = contentObj.stateCode
                        }
                    }
                    break
            }
        },

        showDialog(index) {
            switch (index) {
                case 0:
                    this.showImport = true;
                    if (!this.accountSelectList || this.accountSelectList.length === 0) {
                        this.getAccount()
                    }
                    break;
                // case 1:
                //     this.showAdd = true;
                //     break;
                case 1:
                    this.showTransfer = true;
                    if (!this.accountSelectList || this.accountSelectList.length === 0) {
                        this.getAccount()
                    }
                    break;
                // case 1:
                //     this.$nextTick(() => {
                //         this.showRegister = true
                //     })
                //     // this.$router.push('/register')
                //
                //     break;
                case 2:
                  this.showScanWeb = true
                    break
            }

        },
        setLanguage(val) {
            this.currLanguage = val
        },
        // 快捷回复文字/图片...
        shortcutSize(data) {
            this.sendText = ''
            this.imgValue = []
            this.videoValue = []
            this.audioValue = []
            let { value, type } = data
            this.messageType = type
            if (![2, 3, 4].includes(type)) {
                this.sendText = value
            } else {
                this.sendMessage(value)
                return
            }
            this.sendMessage()
        },
        // 音乐开关
        isPlayChange(val) {
            // console.log(val);
            this.isPlay = val
            localStorage.setItem("isPlay", val)
        },
        // 登出
        loyout() {
            this.$confirm("确认要退出登录吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.logout()
            })
        },
        // 好友转移提交
        moveSubmit() {
            friendMove(this.moveForm).then(res => {
                if (res && res.code === 200) {
                    this.$message.success(res.message)
                    this.showTransfer = false
                }
            })
        },
        // 读取txt文件内容
        handleFileChange(file) {
            // console.log(file)
            const isTxt = file.type === 'text/plain';
            if (!isTxt) {
                this.$message.error('上传文件只能是 txt 格式!');
                return
            }
            if (file) {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = (e) => {
                    this.importForm.friends = e.target.result
                };
            }
        },
        // 导入好友提交
        importSubmit() {
            friendAdd(this.importForm).then(res => {
                if (res && res.code === 200) {
                    this.$message.success(res.message)
                    this.showImport = false
                }
            })
        },
        // 注册成功 需要重拉一下好友列表
        registerEnd() {
            // this.showRegister = false
            this.$refs.userListRef.getFriendList()
        },
        // 更新统计信息
        getReport() {
            this.$refs.rightMenuRef.getAccountReport()
        },
        onInput(event) {
            this.sendText = event.data
        },
        getEmo(index) {
            console.log(this.textFouceIndex)
            let quill = this.$refs.myQuillEditor.quill
            quill.insertEmbed(this.textFouceIndex, 'text', this.faceList[index])
            // 下面是文本域的方法，先注释掉吧
            return
            var textArea = document.getElementById('textarea');
            function changeSelectedText(obj, str) {
                if (window.getSelection) {
                    // 非IE浏览器
                    textArea.setRangeText(str);
                    // 在未选中文本的情况下，重新设置光标位置
                    textArea.selectionStart += str.length;
                    textArea.focus()
                } else if (document.selection) {
                    // IE浏览器
                    obj.focus();
                    var sel = document.selection.createRange();
                    sel.text = str;
                }
            }
            changeSelectedText(textArea, this.faceList[index]);
            this.sendText = textArea.value;// 要同步data中的数据
            this.$refs.popoverRef.doClose();
            // console.log(this.faceList[index]);
            return;
        },
        onEditorChange(e) {
            // 获取光标所在位置
            let quill = this.$refs.myQuillEditor.quill
            this.textFouceIndex = quill.getSelection().index;
            console.log(e.text);
        },
        onEditorFocus() {
            // 这里注释掉 就只能文本最后放置图片/视频...
            // let quill = this.$refs.myQuillEditor.quill
            // this.textFouceIndex = quill.getSelection().index;
            // console.log(this.textFouceIndex);
        },
        // 图片上传成功
        imgValueChange(val) {
            this.loading = false
            this.imgValue.push(val)
            let quill = this.$refs.myQuillEditor.quill
            quill.insertEmbed(this.textFouceIndex, 'image', val)
        },
        audioValueChange(val) {
            this.loading = false
            this.audioValue.push(val)
            let quill = this.$refs.myQuillEditor.quill
            // <svg t="1693756741406" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4829" width="48" height="48"><path d="M160 640h-64V384h64v256z m768-256h-64v256h64V384z m-576-64h-64v384h64V320z m384 0h-64v384h64V320zM544 192h-64v640h64V192z" p-id="4830" fill="#666666"></path></svg>
            quill.insertEmbed(this.textFouceIndex, 'image', 'https://chat.miye009.com/upload_file/quick/11/cv9geerpgz10sqsrot.png' + '?语音=' + val)
        },
        videoValueChange(val) {
            this.loading = false
            this.videoValue.push(val)
            let quill = this.$refs.myQuillEditor.quill
            quill.insertEmbed(this.textFouceIndex, 'video', val)
        }
    }
}
</script>
<style scoped lang="scss">
.chartContent {
    .Logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        text-align: start;
        background-color: rgb(24, 145, 255);
        color: white;
        font-size: 24px;
        font-weight: 600;
    }

    .upLoad {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}

.content {
    overflow-y: auto;
    box-sizing: border-box;
    display: flex;
    height: calc(100vh - 50px);


    .menu {
        width: 200px;
        flex-shrink: 0;
        background-color: rgb(51, 55, 68);
        position: relative;

        .play-witch {
            width: 100%;
            position: absolute;
            bottom: 30px;
            left: auto;
            z-index: 1;
            display: flex;
            align-items: center;
            color: #fff;
            justify-content: center;

            span {
                margin-right: 10px;
            }
        }
    }

}

.chart {
    flex-grow: 1;
    margin: 0 auto;
    height: calc(100vh - 50px);
    background-color: white;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 20px;

    .textarea {
        border: 1px solid rgb(228, 228, 228);
        box-sizing: border-box;
        border-radius: 8px;
        width: 96%;
        margin: 0 auto;

        .textContent {
            padding: 10px;
            position: relative;

            .acc-ban {
                background-color: #3d3b3b;
                color: #cf9236;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 11;
                top: 0px;
                left: 0px;
                text-align: left;
                border-radius: 5px;
            }
        }

        /* 根据禁用状态设置不同的背景颜色 */
        .acc-ban:disabled {
            background-color: #3d3b3b;
            /* 设置禁用时的背景颜色 */
            //font-size: 30px;
        }

        .acc-ban::placeholder {
            color: #cf9236;
        }

        .textHead {
            display: flex;
            width: 100%;
            background-color: rgb(245, 245, 245);
            justify-content: space-between;
            align-items: center;
            padding: 5px 5px;
            box-sizing: border-box;

            .left {
                font-size: 30px;
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 10px;
                }
            }
        }

        textarea {
            width: 100%;
            outline: none;
            border: none;
            resize: none;
            font-size: 15px;
        }
    }

    .chartList {
        padding: 0px 20px;
        overflow-y: auto;
        margin-bottom: 20px;
        flex-grow: 1;
        // scroll-behavior: smooth;
    }

    .head {
        font-size: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        width: 100%;
        padding: 0 20px;
        width: 95%;
        margin: 0 auto;
        border-bottom: 1px solid black;
        height: 60px;
    }
}

.chatIcon {
    padding: 0 10px;
    font-size: 25px;
}

.emotionList {
    display: flex;
    flex-wrap: wrap;
    height: 200px;
    overflow: hidden;
    overflow-y: auto;
}

.emotionItem {
    width: 10%;
    font-size: 20px;
    text-align: center;
}

/*包含以下四种的链接*/
.emotionItem {
    text-decoration: none;
}

/*正常的未被访问过的链接*/
.emotionItem:link {
    text-decoration: none;
}

/*已经访问过的链接*/
.emotionItem:visited {
    text-decoration: none;
}

/*鼠标划过(停留)的链接*/
.emotionItem:hover {
    text-decoration: none;
}

/* 正在点击的链接*/
.emotionItem:active {
    text-decoration: none;
}

.kj-icon {
    font-size: 20px;
    margin-left: 10px;
    color: #666;
    width: 19px;
    height: 19px;
}

.chat-info {
    display: flex;
    align-items: center;
}

.right-align {
    margin-left: auto;
    /* 将元素推到右侧 */
}

::v-deep .editor {
    .ql-editor {
        padding: 0px;
        height: 150px;
    }

    .ql-toolbar {
        display: none;
    }

    .ql-container.ql-snow {
        border: none;
    }
}
</style>

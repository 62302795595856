<template>
    <el-upload ref="upload" :action="`${baseURL}/quickReply/upload`" :before-upload="handlePreview" :headers="headers" :show-file-list="false"
        :on-success="imageSuccess" list-type="picture" :limit="1" :multiple="false" :accept="accept">
        <slot></slot>
    </el-upload>
</template>


<script>
import { baseURL, fileURL } from "@/api/config.js"
export default {
    props: {
        value: {
            type: String | Array
        },
        accept: {
            type: String,
            default: '.jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP'
        }
    },
    data() {
        return {
            headers: {
                Authorization: localStorage.getItem("token")
            },
            baseURL, fileURL,
            imageList: [],
        }
    },
    methods: {
        handlePreview(file) {
        console.log('开始上传');
        this.$emit('openLoading')
      },
        imageSuccess(result) {
            console.log('上传end');
            if (result.code === 200) {
                // this.$emit('update:value', this.fileURL + result.data.path)
                this.$emit('change', this.fileURL + result.data.path)
            }
            this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        },
    }
}
</script>
<style lang="scss" scoped>
.menuItem {
    height: 50px;
    color: rgb(204, 204, 204);
    line-height: 50px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        background-color: rgb(38, 41, 51);
    }
}
</style>


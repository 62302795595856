<template>
  <!-- <div class="login-contrain" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="warp">

    </div>
  </div> -->
  <div class="login-from" element-loading-background="rgba(0, 0, 0, 0.8)">
    <!-- <div class="login-title">
      <i class="el-icon-arrow-left" style="font-weight: bold;margin-right:5px" @click="back"></i>
      <span class="cn">注册账号</span>
    </div> -->

    <el-form ref="form" :model="formData" :rules="rules" label-width="auto">
      <el-form-item required prop="countryId" label="网络地区">
        <el-select filterable v-model="formData.countryId" placeholder="请选择要使用的代理IP地区" style="width: 100%;">
          <el-option v-for="item in downList.countryList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="staticIp" label="指定静态IP">
        <el-input v-model="formData.staticIp" placeholder="格式(地址:端口:账号:密码) 为空则使用系统分配,否则绑定所填写的IP"></el-input>
      </el-form-item>

      <el-form-item v-if="false" required prop="toGroupId" label="选择分组">
        <el-select filterable v-model="formData.toGroupId" placeholder="请选择" style="width: 100%;">
          <el-option v-for="item in downList.accountGroup" :label="item.name" :key="item.id" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item required prop="nickName" label="注册昵称">
        <el-input v-model="formData.nickName" placeholder="对方看到的昵称"></el-input>
      </el-form-item>

      <el-form-item v-if="false" prop="ipModel" label="代理类型">
        <div style="text-align: left">
          <el-radio-group v-model="formData.ipModel">
            <el-radio :label="1">动态IP</el-radio>
            <el-radio :label="2">静态IP</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item required prop="proxyConfigId" label="动态IP" v-if="false">
        <el-select filterable v-model="formData.proxyConfigId" placeholder="请选择" style="width: 100%;">
          <el-option v-for="item in downList.proxyGroup" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item required prop="staticIpId" label="静态IP" v-if='formData.ipModel == 2'>
        <el-select filterable v-model="formData.staticIpId" placeholder="请选择" style="width: 100%;">
          <el-option v-for="item in downList.staticIpList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item required prop="phoneNumber" label="手机号码">
        <el-input v-model="formData.phoneNumber" placeholder="空格和+号会自动识别 但必须带区号"></el-input>
        <button @click.prevent="getCode()" class="code-btn" :disabled="!show || loading" v-loading="loading">
          <span v-show="show">获取验证码</span>
          <span v-show="!show" class="count">{{ count }} s</span>
        </button>
      </el-form-item>


      <el-form-item prop="busType" label="账号类型">
        <div style="text-align: left">
          <el-radio-group v-model="formData.busType">
            <el-radio :label="1">普通版</el-radio>
            <el-radio :label="2">商业版</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item prop="smsType" label="验证码类型">
        <div style="text-align: left">
          <el-radio-group v-model="formData.smsType">
            <el-radio :label="1">短信</el-radio>
            <el-radio :label="2">语音验证码</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item required prop="smsCode" label="验证码">
        <el-input v-model="formData.smsCode" placeholder="请输入验证码"></el-input>

      </el-form-item>
      <el-form-item>
        <el-button style="width: 100%;" type="primary" @click="onSubmit" :loading="loading">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { accountLinkedData, accountRegister } from "@/api/request/api.js"
import { MessageBox } from "element-ui"; // 引入MessageBox
export default {
  components: {
  },
  data() {
    return {
      formData: {
        staticIp:"",
        toGroupId: "",
        ipModel: 1,
        countryId: '',
        staticIpId: '',
        proxyConfigId: '',
        busType: 1,
        smsType: 1,
        phoneNumber: '',
        smsCode: '',
        nickName:'',
      },
      rules: {},
      checked: false,
      loading: false,
      downList: {
        accountGroup: [],
        countryList: [],
        proxyGroup: [],
        staticIpList: []
      },
      show: true,
      count: 0
    }
  },
  mounted() {
    this.getDownList()
  },
  methods: {
    // 获取下拉数据
    getDownList() {
      accountLinkedData().then(res => {
        if (res && res.code === 200) {
          this.downList = res.data
          this.$nextTick(()=>{
            if (this.downList.accountGroup.length < 1){
              this.showErrorDialog("由于版本迭代升级,请联系管理重新为你分配账户")
              return
            }
            this.formData.toGroupId = this.downList.accountGroup[0].id;
            this.formData.countryId = 200;
          })
        }
      })
    },
    async onSubmit() {
      this.loading = true
      const result = await accountRegister(this.formData)
      this.loading = false
      if (result && result.code === 200) {
        this.showSuccessDialog(result.message)
        this.formData.phoneNumber = ''
        this.formData.smsCode = ''
        this.formData.nickName = ''
        this.$emit('registerEnd')
      } else {
        this.showErrorDialog(result.message)
      }
    },
    // 获取短信验证码
    async getCode() {
      this.formData.smsCode = ''
      this.loading = true
      const result = await accountRegister(this.form)
      this.loading = false
      if (result && result.code === 200) {
        this.showSuccessDialog(result.message)
      } else {
        this.showErrorDialog(result.message)
      }
    },

    // 弹窗提示成功信息
    showSuccessDialog(message) {
      MessageBox.alert(message, '成功', {
        confirmButtonText: '确定',
        type: 'success'
      });
    },

    // 弹窗提示错误信息
    showErrorDialog(message) {
      MessageBox.alert(message, '错误', {
        confirmButtonText: '确定',
        type: 'error'
      });
    },

    // 返回上一页
    back() {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss">
.login-contrain {
  // background-color: #fafafa;
  background-image: url('../../assets/login-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .warp {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    display: flex;
    justify-content: center;
  }

  .login-from {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 700px;
    padding: 40px;
    background-color: #fff;

    .login-title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .cn {
        display: 28px;
        font-weight: bold;
        color: #333;
      }

      .en {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}

/* 短信验证码css */
.pr {
  position: relative;
}

.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: #409EFF;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}


</style>

<template>
    <!-- 好友备注|好友号码|好友昵称 -->
    <div class="userItem" :class="isCurrs ? 'active' : ''">
        <div class="userInfo">
            <img class="avatar" :src="getImageSrc(src)" alt="">
            <div :class="['username', { 'red-text': status === 2 }]">{{ nickname }}({{ aid }})
<!--              <el-tag type="warning" v-if="status == 2" size="mini" style="margin-left: 10px;">添加失败</el-tag>-->

            </div>
        </div>
        <p class="messageNum" v-show="unread && messageNum > 0">{{ messageNum }}</p>
        <i style="margin-left: 10px;color: #f56c6c;" class="el-icon-delete-solid" :class="{fd_icon:index == 1}" @click.stop="del"></i>
    </div>
</template>
<script>
import { fileURL } from "@/api/config.js"
import { conversationDelete, friendRemove } from "@/api/request/api.js"
export default {
    props: {
        // 备注
        userName: {
            type: String
        },
        // 头像
        avatar: {
            type: String
        },
        nickname: {
            type: String
        },
        status: {
            type: Number
        },
        aid: {
            type: String | Number
        },
        src: {
            type: String
        },
        unread: {
            type: Number
        },
        isCurr: {
            type: Boolean
        },
        index: {
            type: String | Number
        },
        delId: {
            type: String | Number
        },
    },
    components: {
    },
    watch: {
        unread: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.messageNum = newVal
                }
            },
            deep: true,
            immediate: true
        },
        isCurr: {
            handler(newVal, oldVal) {
                // console.log(newVal)
                this.isCurrs = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            fileURL,
            messageNum: 0,
            isCurrs: false
        }
    },
    methods: {
        getImageSrc(avatarUrl) {
          if (!avatarUrl){
            avatarUrl = '/avatar/default.jpeg'
          }
          if (avatarUrl.charAt(0) === '/') {
            return fileURL+avatarUrl;
          } else {
            return avatarUrl;
          }
        },
        del() {
            this.$confirm("确认要删除吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let params = {}
                if (this.index == 0) {
                    params = {
                        channel_id: this.delId
                    }
                    conversationDelete(params).then(res => {
                        if (res && res.code === 200) {
                            this.$message.success('删除成功')
                            this.$emit('delEnd', this.index)
                        }
                    })
                } else {
                    params = {
                        friendId: this.delId
                    }
                    friendRemove(params).then(res => {
                        if (res && res.code === 200) {
                            this.$message.success('删除成功')
                            this.$emit('delEnd', this.index)
                        }
                    })
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.userItem {
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .messageNum {
        width: 20px;
        height: 20px;
        background-color: #f56c6c;
        border-radius: 50%;
        color: white;
        text-align: center;
        line-height: 20px;
        flex-shrink: 0;
        font-size: 12px;
    }


    .userInfo {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .username {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 265px;
            text-align: left;
        }
        .red-text {
          color: red; /* 设置红色字体颜色 */
        }
    }


    .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        margin-right: 10px;
    }
}

.userItem.active {
    background-color: #ccc;
}
.fd_icon{
    margin-right: 12px;
}
</style>

<template>
    <div class="select">
        <div class="content" @click.stop="openDropDown">
            <span>{{ value }}</span>
            <i class="el-icon-arrow-down"></i>
        </div>
        <div class="dropdown" v-show="showDropDown">
            <el-input placeholder="请输入内容" data-noclose="noClose" prefix-icon="el-icon-search" v-model="search">
            </el-input>
            <div class="selectList">
                <div class="item" v-for="(item, index) in 20" data-noclose="noClose">
                    <span>"6666"</span>
                    <el-checkbox style="margin-right: 10px;"></el-checkbox>
                    <!-- v-model="item.checked" -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String
        },
    },
    data() {
        return {
            showDropDown: false,
            search: "",
        }
    },
    methods: {
        openDropDown() {
            this.showDropDown = !this.showDropDown;
            document.addEventListener("click", this.closeDropDown)
        },
        closeDropDown(e) {
            if (e.target.dataset.noclose) {
                return
            }
            this.showDropDown = false;
            document.removeEventListener("click", this.closeDropDown)


        },
    }
}
</script>

<style lang="scss" scoped>
.select {
    border: 1px solid rgb(220, 223, 230);
    height: 40px;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    &:hover {
        border-color: rgb(192, 196, 204);
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .dropdown {
        max-height: 300px;
        overflow-y: auto;
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box;
        position: absolute;
        background-color: white;
        width: inherit;
        // padding: 0 15px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        left: 0;
        margin-top: 12px;
        z-index: 99;

        .selectList {
            margin-top: 10px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: start;

            &:hover {
                background-color: rgb(245, 247, 250);
            }
        }
    }
}
</style>

<template>
    <div class="userMenu" v-loading="loading">
        <div class="tabs">
            <div class="tabItem" :class="{ active: currentActive === 0 }" @click="changeActive(0)">
                <span>消息</span>
                <span class="badge" v-if="badgeNum && badgeNum > 0">{{ badgeNum }}</span>
                <p class="line"></p>
            </div>
            <div class="tabItem" :class="{ active: currentActive === 1 }" @click="changeActive(1)">
                <span>好友</span>
                <p class="line"></p>
            </div>
        </div>
        <div class="userList" v-show="currentActive === 0">
            <UserItem @click.native="changeContent(item, 'chart')" v-for="(item, index) in conversationArr"
                :userName="item.friendInfo.remark" :nickname="item.friendInfo.nickname" :aid="item.friendInfo.phoneNumber"
                :src="item.friendInfo.avatarUrl" :unread="item.unread" :delId="item.channel_id" :index="currentActive" :status="item.accountInfo.status"
                @delEnd="delEnd" :isCurr="item.channel_id == currId" />
            <el-empty v-if="!conversationArr || conversationArr.length === 0" description="暂无会话"></el-empty>
        </div>
        <div class="friendList" v-show="currentActive === 1">
            <el-collapse v-model="activeName" style="padding: 0px 0px;" @change="collapseChange">
                <el-collapse-item :name="item.accountId" v-for="(item, index) in friendList">
                    <template slot="title">
                        <div style="padding-left: 10px;display: flex;align-items: center;flex: 1;">
                            <img class="avatarUrl"
                                :src="getImageSrc(item.avatarUrl)" alt=""> {{
                                    `${item.nickname}(${item.phoneNumber})` }}
                          <i style="float: right;color: cornflowerblue;margin-left: 10px;"
                             class="el-icon-edit-outline" @click.stop="editAccountInfo(item.phoneNumber)"></i>

                          <el-tag type="success" v-if="item.status == 1" size="mini"
                                style="margin-left: 10px;">在线</el-tag>
                            <el-tag type="danger" v-if="item.status == 2" size="mini" style="margin-left: 10px;">封号</el-tag>
                          <el-tag type="warning" v-if="item.status == 3" size="mini"
                                style="margin-left: 10px;">重连中..</el-tag>
                          <el-tag type="warning" v-if="item.status == 4" size="mini"
                                  style="margin-left: 10px;">掉登</el-tag>


                          <i style="margin-left: -10px;float: right;color: #f56c6c;margin-left: auto;margin-right: 10px;"
                                class="el-icon-delete-solid" @click.stop="del(item.accountId)"></i>

                        </div>
                    </template>
                    <UserItem @click.native="changeContent(child, 'friends')" v-for="(child, index) in item.friends"
                        :userName="child.remark" :nickname="child.nickname" :aid="child.phoneNumber" :src="child.avatarUrl"
                        :isCurr="child.channelId == currId" :status="child.status" :delId="child.friendId"
                        :index="currentActive" @delEnd="delEnd" />
                </el-collapse-item>
            </el-collapse>
            <el-empty v-if="!friendList || friendList.length === 0" description="暂无好友"></el-empty>
        </div>

        <EditAccountInfo ref="edieAccountInfo" />

    </div>
</template>
<script>
import UserItem from "./UserItem.vue"
import { getFriendList, getConversation, setUnread ,accountRemove} from "@/api/request/api.js"
import { fileURL } from "@/api/config.js"
import register from "@/views/register/index.vue";
import EditAccountInfo from "@/views/Home/components/EditAccountInfo.vue";
export default {
    created() {
        this.getFriendList();
        this.getConversation(true);
    },
    components: {
      EditAccountInfo,
      register,
        UserItem
    },
    props: {
        channelId: {
            type: String | Number,
        }
    },
    watch: {
        channelId: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // try {
                    //     this.friendList.forEach((item,index) => {
                    //         if (item.channel_id == newVal) {
                    //             console.log('高亮',item.channel_id)
                    //             this.friendList[index].unread += 1
                    //             throw Error();
                    //         }
                    //     });
                    // } catch (error) {
                    //     return;
                    // }
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        badgeNum() {
            let num = 0
            this.conversationArr.forEach((item, index) => {
                if (item.unread && item.unread > 0) {
                    num += item.unread
                }
            });
            return num
        }
    },
    data() {
        return {
            currentActive: 0,
            friendList: [],
            conversationArr: [],
            activeName: "1",
            tabsArr: [
                {
                    name: "会话"
                },
                {
                    name: "账号"
                }
            ],
            currId: "",
            fileURL,
            loading: true
        }
    },
    methods: {
        async changeContent(item, model) {
            this.$emit("changeContent", {
                value: item,
                model,
            })
            this.currId = item.channel_id || item.channelId
            const result = await setUnread({
                channel_id: this.currId,
                unread: 0
            });
            if (result.code === 200) {
                this.getConversation(false);
            }
        },
        collapseChange(value) {
            //todo 点击账号事件
        },
        async getFriendList() {
            const result = await getFriendList();
            if (result.code === 200) {
                if (result.data && result.data.length > 0) {
                    this.friendList = result.data
                } else {
                    this.friendList = []
                }

            }
        },
        // 回去会话列表
        async getConversation(boo) {
            if (boo) {
                this.loading = true
            }
            const result = await getConversation({
                "version": 0,
                "msg_count": 1
            });
            this.loading = false
            if (result.code === 200) {
                this.conversationArr = result.data
                // 是否首次加载
                if (boo) {
                    if (this.conversationArr.length > 0) {
                        this.$emit("changeContent", {
                            value: this.conversationArr[0],
                            model: 'chart',
                        })
                        this.currId = this.conversationArr[0].channel_id
                    }

                }
            }
        },
        changeActive(index) {
            if (index == 0) {
                this.getConversation();
            } else {
                this.getFriendList()
            }
            this.currentActive = index
            this.$emit('getReport')
        },
        // 新消息进来给对应的未读数➕1
        setUnreads(newVal) {
            try {
                this.conversationArr.forEach((item, index) => {
                    if (item.channel_id == newVal) {
                        // console.log(item.channel_id + '未读加1')
                        this.friendList[index].unread += 1
                        this.$set(this.conversationArr[index], 'unread', this.conversationArr[index].unread + 1)
                        throw Error();
                    }
                });
            } catch (error) {
                return;
            }
        },
        // 删除成功
        delEnd(index) {
            this.changeActive(index)
        },
        del(id) {
            this.$confirm("确认要删除吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let params = {
                    accountId: id
                }
                accountRemove(params).then(res => {
                    if (res && res.code === 200) {
                        this.$message.success('删除成功')
                        this.getFriendList()
                    }
                })

            })
        },
        editAccountInfo(phoneNumber){
          this.$refs.edieAccountInfo.editAccountInfoShow(phoneNumber)
        },
        getImageSrc(avatarUrl) {
          if (!avatarUrl){
            avatarUrl = '/avatar/default.jpeg'
          }
          if (avatarUrl.charAt(0) === '/') {
            return fileURL+avatarUrl;
          } else {
            return avatarUrl;
          }
        }
    }
}
</script>
<style scoped lang="scss">
.userMenu {
    width: 400px;
    flex-shrink: 0;

    .tabs {
        display: flex;

        .tabItem {
            cursor: pointer;
            flex-basis: 33%;
            height: 50px;
            line-height: 50px;
            position: relative;

            .line {
                width: 100%;
                height: 2px;
                // background-color: white;
            }

            .badge {
                width: 20px;
                height: 20px;
                background-color: #f56c6c;
                border-radius: 50%;
                color: white;
                text-align: center;
                line-height: 20px;
                font-size: 12px;
                display: inline-block;
                vertical-align: text-top;
                margin-left: 5px;
            }
        }

        .active {
            color: rgb(64, 158, 255);

            .line {
                background-color: rgb(64, 158, 255);
            }
        }
    }

    .userList {
        // height: 100%;
        height: calc(100vh - 100px);
        overflow-y: auto;
    }

    .friendList {
        height: calc(100vh - 100px);
        overflow-y: auto;
    }
}

.avatarUrl {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.tab-badge {
    height: 30px;
}</style>

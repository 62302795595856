<template>
    <div class="translate">
        <div class="top">
            <div class="tabs">
                <div class="tabItem" :class="{ active: currentPart === index }" v-for="(item, index) in tabsArr"
                    @click="changeActive(index)">
                    <span>{{ item.name }}</span>
                    <p class="line"></p>
                </div>
            </div>
            <div class="content">
                <div class="part1" v-show="currentPart === 0">
                    <div class="select">
                        <!-- <el-select v-model="preCode" placeholder="请选择" style="width: 150px;">
                        <el-option v-for="item in translateList" :key="item.code" :label="item.name" :value="item.code">
                        </el-option>
                    </el-select> -->
                        <el-input placeholder="自动识别" readonly style="width: 150px;"></el-input>
                        <i class="el-icon-sort icon"></i>
                        <el-select v-model="form.targetLanguage" placeholder="请选择" style="width: 150px;"
                            @change="targetChange">
                            <el-option v-for="item in translateList" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="position:relative">
                        <textarea v-model="form.text" name="" id="" cols="30" rows="5"></textarea>
                        <el-button class="btn" type="primary" size="small" :loading="loading"
                            @click.stop="textareaInput">翻译</el-button>
                    </div>
                    <div class="result">
                        翻译结果：{{ translateResult }}
                    </div>
                </div>

                <div class="part2" v-show="currentPart === 1">
                    <el-collapse>
                        <el-collapse-item title="快捷回复文字" name="1">
                            <el-button type="warning" style="width: 100%;" @click="addQuick('text')"
                                size="mini">添加快捷回复文字</el-button>
                            <el-table :data="quickText">
                                <el-table-column prop="tag" label="标签名" width="100px" align="center">
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="250px">
                                    <template slot-scope="scope">
                                        <el-button plain size="mini" type="success"
                                            @click="send(scope.row.content, 1)">发送</el-button>
                                        <el-button plain size="mini" type="primary"
                                            @click="editText(scope.row)">编辑</el-button>
                                        <el-button plain size="mini" type="danger"
                                            @click="removeQuickReply(scope.row.id)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                        <el-collapse-item title="快捷回复名片" name="5">
                            <el-button type="warning" style="width: 100%;" @click="addQuick('card')"
                                size="mini">添加快捷回复名片</el-button>

                            <el-table :data="quickCard">
                                <el-table-column prop="tag" label="标签名" align="center" width="100px">
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="250px">
                                    <template slot-scope="scope">
                                        <el-button plain size="mini" type="success"
                                            @click="send(scope.row.content, 5)">发送</el-button>
                                        <el-button plain size="mini" type="primary"
                                            @click="editCard(scope.row)">编辑</el-button>
                                        <el-button plain size="mini" type="danger"
                                            @click="removeQuickReply(scope.row.id)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                        <el-collapse-item title="快捷回复图片" name="2">
                            <el-button type="warning" style="width: 100%;" @click="addQuick('image')"
                                size="mini">添加快捷回复图片</el-button>
                            <el-table :data="quickPhoto">
                                <el-table-column prop="tag" label="标签名" align="center" width="100px">
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="250px">
                                    <template slot-scope="scope">
                                        <el-button plain size="mini" type="success"
                                            @click="send(fileURL + scope.row.content, 2)">发送</el-button>
                                        <el-button plain size="mini" type="primary"
                                            @click="editImage(scope.row)">编辑</el-button>
                                        <el-button plain size="mini" type="danger"
                                            @click="removeQuickReply(scope.row.id)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                        <el-collapse-item title="快捷回复语音" name="3">
                            <el-button type="warning" style="width: 100%;" @click="addQuick('audio')"
                                size="mini">添加快捷回复语音</el-button>

                            <el-table :data="quickAudio">
                                <el-table-column prop="tag" label="标签名" align="center" width="100px">
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="250px">
                                    <template slot-scope="scope">
                                        <el-button plain size="mini" type="success"
                                            @click="send(fileURL + scope.row.content, 3)">发送</el-button>
                                        <el-button plain size="mini" type="primary"
                                            @click="editVoice(scope.row)">编辑</el-button>
                                        <el-button plain size="mini" type="danger"
                                            @click="removeQuickReply(scope.row.id)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                        <el-collapse-item title="快捷回复视频" name="4">
                            <el-button type="warning" style="width: 100%;" @click="addQuick('video')"
                                size="mini">添加快捷回复视频</el-button>
                            <el-table :data="quickVideo">
                                <el-table-column prop="tag" label="标签名" align="center" width="100px">
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="250px">
                                    <template slot-scope="scope">
                                        <el-button plain size="mini" type="success"
                                            @click="send(fileURL + scope.row.content.videoPath, 4)">发送</el-button>
                                        <el-button plain size="mini" type="primary"
                                            @click="editVideo(scope.row)">编辑</el-button>
                                        <el-button plain size="mini" type="danger"
                                            @click="removeQuickReply(scope.row.id)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>

                    </el-collapse>
                </div>
                <div class="part3" v-show="currentPart === 2">
                    <div class="infoItem">
                        <span class="key">好友账号：</span><span>{{ friendInfo.friendId }}</span>
                    </div>
                    <div class="infoItem">
                        <span class="key">好友昵称：</span><span>{{ friendInfo.nickname }}</span>
                    </div>
                    <div class="infoItem">
                        <span class="key">个人关于：</span><span>{{ friendInfo.stateText }}</span>
                    </div>
                    <div class="infoItem">
                        <span class="key">注册时间：</span><span>{{ friendInfo.createdAt }}</span>
                    </div>
                    <div class="infoItem">
                        <span class="key">备注：</span>
                        <el-input v-model="editRemarkForm.remark" placeholder="请输入内容" v-show="isEdit"></el-input>
                        <span v-show="!isEdit">{{ friendInfo.remark }}</span>
                        <el-button class="btn" type="success" size="small" @click="saveRemark">{{ isEdit ? '保存' : '修改备注'
                        }}</el-button>
                    </div>
                    <div class="infoItem">
                        <span class="key">归属账号：</span><span>{{ friendInfo.ownerAccount }}</span>
                    </div>
                    <el-button class="uptag" type="success" size="small" @click="uptagFriendInfo">更新数据</el-button>
                </div>

                <div class="report" v-loading="reportLoading">
                    <div class="tabs">
                        <div class="tabItem active" style="text-align: center">
                            <span>上粉统计</span>
                            <p class="line"></p>
                        </div>
                    </div>
                    <div style="padding: 20px;">
                        <div class="text item">
                            客服今日上粉：
                            <span class="today_count">{{ accountReportForm.custReport.todayCount || 0 }}</span>
                        </div>
                        <div class="text item">
                            客服昨日上粉：
                            <span class="yest_count">{{ accountReportForm.custReport.yestCount || 0 }}</span>
                        </div>
                        <div class="text item">
                            客服上粉总数：
                            <span class="all_count">{{ accountReportForm.custReport.allCount || 0 }}</span>
                        </div>
                        <div class="line"></div>
                        <div class="text item">
                            账号今日上粉：
                            <span class="today_count">{{ accountReportForm.accountReport.todayCount || 0 }}</span>
                        </div>
                        <div class="text item">
                            账号昨日上粉：
                            <span class="yest_count">{{ accountReportForm.accountReport.yestCount || 0 }}</span>
                        </div>
                        <div class="text item">
                            账号上粉总数：
                            <span class="all_count">{{ accountReportForm.accountReport.allCount || 0 }}</span>
                            <el-button style="float: right; padding: 3px 0" type="text"
                                @click="getAccountReport">刷新</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="添加快捷回复文字" :visible.sync="textAdd" width="30%" :before-close="textClose">
            <el-form :model="textForm" :rules="rules1" ref="textForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标签名：" prop="tag">
                    <el-input v-model="textForm.tag"></el-input>
                </el-form-item>
                <el-form-item label="文字内容：" prop="content">
                    <el-input type="textarea" v-model="textForm.content"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addText('textForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="添加快捷回复图片" :visible.sync="imageAdd" width="30%" :before-close="imageClose">
            <el-form :model="imageForm" :rules="rules2" ref="imageForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标签名：" prop="tag">
                    <el-input v-model="imageForm.tag"></el-input>
                </el-form-item>
                <el-form-item label="图片内容：" prop="content" align="left">
                    <el-upload :action="`${baseURL}/quickReply/upload`" :headers="headers" :on-success="imageSuccess"
                        :file-list="imageList" list-type="picture" :limit="1">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">图片尺寸需要大于100 * 100像素，支持jpg、png、jpeg等格式，大小不能超过2MB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addImage('imageForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="添加快捷回复语音" :visible.sync="voiceAdd" width="30%" :before-close="voiceClose">
            <el-form :model="voiceForm" :rules="rules3" ref="voiceForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标签名：" prop="tag">
                    <el-input v-model="voiceForm.tag"></el-input>
                </el-form-item>
                <el-form-item label="语音内容：" prop="content" align="left">
                    <!-- <el-upload :action="`${baseURL}/quickReply/upload`" :headers="headers" :on-success="voiceSuccess"
                        :file-list="voiceList" list-type="picture" :limit="1">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">图片尺寸需要大于100 * 100像素，支持jpg、png、jpeg等格式，大小不能超过2MB</div>
                    </el-upload> -->
                    <el-upload :action="`${baseURL}/quickReply/upload`" :file-list="voiceList" :limit="1" :headers="headers"
                        :on-success="voiceSuccess">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">支持MP3等格式，大小不能超过2M，语音时长不超过60秒</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addVoice('voiceForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="添加快捷回复视频" :visible.sync="videoAdd" width="30%" :before-close="videoClose">
            <el-form :model="videoForm" :rules="rules4" ref="videoForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标签名：" prop="tag">
                    <el-input v-model="videoForm.tag"></el-input>
                </el-form-item>
                <el-form-item label="封面：" prop="content.videoPath" align="left">
                    <el-upload :action="`${baseURL}/quickReply/upload`" :headers="headers" :on-success="videoImageSuccess"
                        :file-list="videoImageList" list-type="picture" :limit="1">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">图片尺寸需要大于100 * 100像素，支持jpg、png、jpeg等格式，大小不能超过2MB</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="上传视频：" prop="content.imagePath" align="left">
                    <el-upload :action="`${baseURL}/quickReply/upload`" :file-list="videoList" :limit="1" :headers="headers"
                        :on-success="videoSuccess">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">文件大小不能超过10MB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addVideo('videoForm')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="添加快捷回复名片" :visible.sync="cardAdd" width="30%" :before-close="cardClose">
            <el-form :model="cardForm" :rules="rules5" ref="cardForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标签名：" prop="tag">
                    <el-input v-model="cardForm.tag"></el-input>
                </el-form-item>
                <el-form-item label="名片内容：" prop="content">
                    <el-input type="textarea" v-model="cardForm.content"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addCard('cardForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getLanguageList, translation, editRemark, uptagFriendInfo, getQuickReply, removeQuickReply, addText, addImage, addVoice, addVideo, addCard, accountReport } from "@/api/request/api.js"
import store from "@/store/index.js"
import { baseURL, fileURL } from "@/api/config.js"

export default {
    created() {
        this.getLanguageList();
        this.getQuickReply();
    },
    props: {
        userName: {
            type: String
        },
        avatar: {
            type: String

        }
    },
    computed: {
        friendInfo() {
            if (this.$store.state.friendInfo) {
                // console.log(this.$store.state.friendInfo)
                return this.$store.state.friendInfo
            } else {
                return ""
            }

        }
    },
    components: {
    },
    data() {
        return {
            headers: {
                Authorization: localStorage.getItem("token")
            },
            baseURL,fileURL,
            isEdit: false,
            editRemarkForm: {
                friendId: "",
                remark: "",
            },
            remark: "",
            translateResult: "",
            translateList: [],
            value: "",
            currentPart: 0,
            tabsArr: [
                {
                    name: "翻译助手"
                },
                {
                    name: "快捷回复"
                },
                {
                    name: "好友信息"
                },
            ],
            form: {
                text: "",
                targetLanguage: ""
            },
            preCode: "",
            timer: null,
            quickText: [],
            quickPhoto: [],
            quickAudio: [],
            quickVideo: [],
            quickCard: [],
            textAdd: false,
            imageAdd: false,
            voiceAdd: false,
            videoAdd: false,
            cardAdd: false,
            rules1: {
                tag: [
                    { required: true, message: '请输入标签', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ],
            },
            rules2: {
                tag: [
                    { required: true, message: '请输入标签', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请上传文件', trigger: 'blur' },
                ],
            },
            rules2: {
                tag: [
                    { required: true, message: '请输入标签', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请上传文件', trigger: 'blur' },
                ],
            },
            rules3: {
                tag: [
                    { required: true, message: '请输入标签', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请上传文件', trigger: 'blur' },
                ],
            },
            rules4: {
                tag: [
                    { required: true, message: '请输入标签', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ],

            },
            rules5: {
                tag: [
                    { required: true, message: '请输入标签', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                ],
            },
            textForm: {
                id: null,
                tag: "",
                content: "",
            },
            imageForm: {
                id: null,
                tag: "",
                content: "",
            },
            voiceForm: {
                id: null,
                tag: "",
                content: "",
            },
            videoForm: {
                id: null,
                tag: "",
                content: {
                    videoPath: "",
                    imagePath: "",
                },
            },
            cardForm: {
                id: null,
                tag: "",
                content: "",
            },
            imageList: [],
            voiceList: [],
            videoList: [],
            videoImageList: [],
            videoList: [],
            loading: false,
            accountReportForm: {
                accountReport: {

                },
                custReport: {

                }
            },
            reportLoading: false
        }
    },
    mounted() {
        this.getAccountReport()
    },
    methods: {
        // 获取账号统计
        getAccountReport() {
            let params = {
                accountId: localStorage.getItem("accountId")
            }
            this.reportLoading = true
            accountReport(params).then(res => {
                this.reportLoading = false
                if (res && res.code === 200) {
                    this.accountReportForm = res.data.fansReport
                    // console.log(this.accountReportForm)
                }
            })
        },
        videoImageSuccess(result) {
            if (result.code === 200) {
                this.videoForm.content.imagePath = result.data.path
            }
        },
        videoSuccess(result) {
            if (result.code === 200) {
                this.videoForm.content.videoPath = result.data.path
            }
        },
        imageSuccess(result) {
            if (result.code === 200) {
                this.imageForm.content = result.data.path
            }
        },
        voiceSuccess(result) {
            if (result.code === 200) {
                this.voiceForm.content = result.data.path
            }
        },
        removeImage() {

        },
        textClose() {
            this.textAdd = false
            for (const key in this.textForm) {
                this.textForm[key] = null
            }
        },
        imageClose() {
            this.imageAdd = false
            for (const key in this.imageForm) {
                this.imageForm[key] = null
            }
            this.imageList = []
        },
        voiceClose() {
            this.voiceAdd = false
            for (const key in this.imageForm) {
                this.voiceForm[key] = null
            }
            this.voiceList = []

        },
        videoClose() {
            this.videoAdd = false
            this.videoForm.id = null
            this.videoForm.tag = null
            this.videoForm.content.videoPath = null
            this.videoForm.content.imagePath = null
            this.videoImageList = []
            this.videoList = []


        },
        cardClose() {
            this.cardAdd = false
            for (const key in this.cardForm) {
                this.cardForm[key] = null
            }
        },
        editText(row) {
            this.textAdd = true
            for (const key in this.textForm) {
                this.textForm[key] = row[key]
            }

        },
        editImage(row) {
            // console.log(row)
            this.imageAdd = true
            for (const key in this.imageForm) {
                this.imageForm[key] = row[key]
            }
            this.videoList.push({
                name: row.content,
                url: `${baseURL}${row.content}`
            })
        },
        editVideo(row) {
            this.videoAdd = true
            this.videoForm.id = row.id
            this.videoForm.tag = row.tag
            this.videoImageList.push({
                name: row.content.imagePath,
                url: `${baseURL}${row.content}`
            })
            this.videoList.push({
                name: row.content.videoPath,
                url: `${baseURL}${row.content}`
            })
        },
        editVoice(row) {
            this.voiceAdd = true
            for (const key in this.voiceForm) {
                this.voiceForm[key] = row[key]
            }
            this.voiceList.push({
                name: row.content,
                url: `${baseURL}${row.content}`
            })
        },
        editCard(row) {
            this.cardAdd = true
            for (const key in this.cardForm) {
                this.cardForm[key] = row[key]
            }
        },
        addVoice(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.voiceForm.id) {
                        const result = await addVoice(this.voiceForm);
                        if (result.code === 200) {
                            this.$message.success("修改成功")
                            this.voiceAdd = false
                        }
                    } else {
                        const result = await addVoice(this.voiceForm);
                        if (result.code === 200) {
                            this.$message.success("添加成功")
                            this.voiceAdd = false
                        }
                    }
                    this.getQuickReply();
                } else {
                    return false;
                }
            });
        },
        addText(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.textForm.id) {
                        const result = await addText(this.textForm);
                        if (result.code === 200) {
                            this.$message.success("修改成功")
                            this.textAdd = false
                        }
                    } else {
                        const result = await addText(this.textForm);
                        if (result.code === 200) {
                            this.$message.success("添加成功")
                            this.textAdd = false
                        }
                    }
                    this.getQuickReply();
                } else {
                    return false;
                }
            });
        },
        addImage(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.imageForm.id) {
                        const result = await addImage(this.imageForm);
                        if (result.code === 200) {
                            this.$message.success("修改成功")
                            this.imageAdd = false
                        }
                    } else {
                        const result = await addImage(this.imageForm);
                        if (result.code === 200) {
                            this.$message.success("添加成功")
                            this.imageAdd = false
                        }
                    }
                    this.getQuickReply();
                } else {
                    return false;
                }
            });
        },
        addVideo(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.videoForm.id) {
                        const result = await addVideo(this.videoForm);
                        if (result.code === 200) {
                            this.$message.success("修改成功")
                            this.videoAdd = false
                        }
                    } else {
                        const result = await addVideo(this.videoForm);
                        if (result.code === 200) {
                            this.$message.success("添加成功")
                            this.videoAdd = false
                        }
                    }
                    this.getQuickReply();
                } else {
                    return false;
                }
            });
        },
        addCard(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.cardForm.id) {
                        const result = await addCard(this.cardForm);
                        if (result.code === 200) {
                            this.$message.success("修改成功")
                            this.cardAdd = false
                        } else {
                            this.$message.error(result.message)
                        }
                    } else {
                        const result = await addCard(this.cardForm);
                        if (result.code === 200) {
                            this.$message.success("添加成功")
                            this.cardAdd = false
                        } else {
                            this.$message.error(result.message)
                        }
                    }
                    this.getQuickReply();
                } else {
                    return false;
                }
            });
        },
        handleClose() {
            this.textAdd = false
            this.imageAdd = false
            this.voiceAdd = false
            this.videoAdd = false
            this.cardAdd = false
        },
        addQuick(type) {
            switch (type) {
                case 'text':
                    this.textAdd = true;
                    break;
                case 'image':
                    this.imageAdd = true;
                    break;
                case 'audio':
                    this.voiceAdd = true;
                    break;
                case 'video':
                    this.videoAdd = true;
                    break;
                case 'card':
                    this.cardAdd = true;
                    break;
            }
        },
        async removeQuickReply(id) {
            const result = await removeQuickReply({ id });
            if (result.code === 200) {
                this.$message.success("删除成功")
                this.getQuickReply();

            }
        },
        async getQuickReply() {
            const result = await getQuickReply();
            if (result.code === 200) {
                this.quickText = result.data.textList
                this.quickPhoto = result.data.imageList
                this.quickAudio = result.data.voiceList
                this.quickVideo = result.data.videoList
                this.quickCard = result.data.cardList
            }
        },
        async uptagFriendInfo() {
            const result = await uptagFriendInfo({
                channelId: this.friendInfo.channelId
            });
            if (result.code === 200) {
                store.commit("SET_FRIENDINFO", result.data)
                this.$message.success("更新成功")
            }
        },
        async saveRemark() {
            this.editRemarkForm.friendId = this.friendInfo.friendId
            this.editRemarkForm.remark = this.friendInfo.remark
            if (this.isEdit) {
                const result = await editRemark(this.editRemarkForm);
                if (result.code === 200) {
                    // console.log(result)
                    this.$message.success("修改成功")
                }
            }
            this.isEdit = !this.isEdit;


        },
        targetChange(value) {
            this.form.targetLanguage = value;
            this.$emit('setLanguage', this.form.targetLanguage)
            // this.textareaInput();
        },
        async textareaInput() {
            // if (this.timer) {
            //     clearTimeout(this.timer)
            // }
            // this.timer = setTimeout(async () => {

            // }, 2000)
            if (this.form.targetLanguage === "") {
                this.$message.error("请选择要翻译的目标语言")
                return;
            }
            this.loading = true
            const result = await translation(this.form);
            this.loading = false
            if (result.code === 200) {
                this.translateResult = result.data.translations
            } else {
                this.$message.error(result.message)
            }
        },
        async getLanguageList() {
            const result = await getLanguageList(this.form);
            if (result.code === 200) {
                this.translateList = result.data
                this.form.targetLanguage = 'en'
                this.$emit('setLanguage', this.form.targetLanguage)
            }
        },
        changeActive(index) {
            this.currentPart = index
        },
        // 快捷回复文字
        send(val, type) {
            this.$emit('shortcut', { value: val, type })
        }
    }
}
</script>
<style scoped lang="scss">
.translate {
    min-width: 400px;
    background-color: rgb(245, 245, 245);

    .top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .part1 {
            padding: 10px 20px 0px 20px;
            position: relative;

            textarea {
                padding: 10px;
                width: 100%;
                box-sizing: border-box;
                outline: none;
                margin-top: 20px;
                border: none;
                background-color: white;
                display: block;
                resize: none
            }

            .btn {
                position: absolute;
                right: 5px;
                bottom: 5px;
                z-index: 1;
            }

            .result {
                max-width: 360px;
                text-align: left;
                padding-top: 10px;
            }

            .select {

                .icon {
                    transform: rotate(90deg);
                    margin: 0 20px;
                    font-size: 20px;
                }
            }

        }

        .part2 {
            padding: 10px 10px 0px 10px;
            height: calc(100vh - 350px);
            overflow-y: auto;
            background-color: white;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .part3 {
            padding: 10px 20px 0px 20px;

            .uptag {
                margin-top: 20px;
            }

            .infoItem {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 20px;

                .key {
                    flex-basis: 100px;
                    text-align: start;
                }

                .btn {
                    margin-left: 20px;
                }
            }
        }
    }

    .tabs {
        display: flex;
        height: 55px;

        .tabItem {
            cursor: pointer;
            flex-basis: 33%;
            height: 50px;
            line-height: 50px;

            .line {
                width: 100%;
                height: 2px;
                background-color: rgb(245, 245, 245);
            }
        }

        .active {
            color: rgb(64, 158, 255);

            .line {
                background-color: rgb(64, 158, 255);
            }
        }
    }

    .report {
        text-align: left;

        .text {
            font-size: 14px;
        }
    }
}

.line {
    border-bottom: 1px solid #DCDFE6;
    margin: 10px 0px;
}

.yest_count {
    color: #7594b4;
}

.today_count {
    color: blue;
    font-weight: bold;
}

.all_count {
    color: #3a8ee6;
    font-weight: bold;
}</style>

<template>
    <div class="chartItem">
        <div class="rightInfo">
            <p class="name">{{ info.nickname }}</p>
            <div class="chartContent">
                <div v-if="![2, 3, 4].includes(type)">
                    <div class="en" v-if="en" v-html="en"></div>
                    <div class="line" v-if="en"></div>
                    <div class="cn" v-html="cn"></div>
                </div>
                <other-file-type :type="type" :cn="cn"></other-file-type>
                <!-- stateCode -->
                <div class="err_address">
                    <el-tooltip class="item" v-if="stateCode == 2" effect="dark" :content="sendState" placement="top">
                        <el-button type="text"><i style="color: #f56c6c;font-size: 24px;"
                                class="el-icon-warning"></i></el-button>
                    </el-tooltip>
                    <i v-if="stateCode == 0" style="font-size: 24px;color:#999" class="el-icon-loading"></i>
                    <i v-if="stateCode == 1" style="font-size: 24px;color:#67c23a" class="el-icon-success"></i>
                </div>
            </div>
        </div>
        <img class="headImg" :src="getImageSrc(info.avatarUrl)" alt="">
    </div>
</template>
<script>
import { fileURL } from "@/api/config.js"
import otherFileType  from './otherFileType/index.vue'
export default {
    components: { otherFileType },
    props: {
        userName: {
            type: String,
        },
        en: {
            type: String,

        },
        cn: {
            type: String,
        },
        info: {
            type: Object | String
        },
        stateCode: {
            type: Number | String
        },
        sendState: {
            type: String,
        },
        // 1文字，2图片，3语言，4视频，5名片
        type: {
            type: Number | String,
        },
    },
    data() {
        return {
            fileURL,
            audioPlayIng: false
        }
    },
    methods: {
      getImageSrc(avatarUrl) {
        if (!avatarUrl){
          avatarUrl = '/avatar/default.jpeg'
        }
        if (avatarUrl.charAt(0) === '/') {
          return fileURL+avatarUrl;
        } else {
          return avatarUrl;
        }
      },
        playAudio(url) {
            this.$refs.sendAudio.src = url
            if (!this.audioPlayIng) {
                this.$refs.sendAudio.play()
            } else {
                this.$refs.sendAudio.pause()
            }
            this.audioPlayIng = !this.audioPlayIng
        },
        // 音频播放完毕
        overAudio() {
            this.audioPlayIng = false
        }
    }
}
</script>
<style scoped lang="scss">
.chartItem {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    .rightInfo {
        max-width: 82.3%;

        .name {
            font-weight: bold;
            text-align: end;
        }

        .chartContent {
            word-wrap: break-word;
            margin-top: 10px;
            padding: 10px;
            box-sizing: border-box;
            border: 1px solid rgb(121, 121, 121);
            border-radius: 6px;
            color: white;
            background-color: rgb(64, 158, 255);
            text-align: right;
            position: relative;

            .err_address {
                position: absolute;
                left: -40px;
                bottom: 0px;
                z-index: 1;
            }

            .line {
                height: 2px;
                margin: 10px 0px;
                width: 100%;
                background-color: white;
            }

        }
    }

    .headImg {
        width: 64px;
        height: 64px;
        margin-left: 20px;
        flex-shrink: 0;
    }

}

// .chat-img {
//     display: flex;
//     align-items: center;

//     .spinner {
//         width: 60px;
//         height: 15px;
//         text-align: center;
//         cursor: pointer;
//     }

//     .spinner>div {
//         background-color: #fff;
//         height: 100%;
//         width: 2px;
//         margin: auto 2px;
//         display: inline-block;
//         -webkit-transform: scaleY(0.4);

//     }

//     .spinner .rect3,
//     .spinner .rect5 {
//         -webkit-transform: scaleY(0.8);
//     }

//     .spinner .rect4 {
//         -webkit-transform: scaleY(1);
//     }

//     .spinnering>div {
//         -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
//         animation: stretchdelay 1.2s infinite ease-in-out;
//     }

//     .spinnering .rect2 {
//         -webkit-animation-delay: -1.1s;
//         animation-delay: -1.1s;
//     }

//     .spinnering .rect3 {
//         -webkit-animation-delay: -1s;
//         animation-delay: -1s;
//     }

//     .spinnering .rect4 {
//         -webkit-animation-delay: -0.9s;
//         animation-delay: -0.9s;
//     }

//     .spinnering .rect5 {
//         -webkit-animation-delay: -0.8s;
//         animation-delay: -0.8s;
//     }

//     .spinnering .rect6 {
//         -webkit-animation-delay: -0.7s;
//         animation-delay: -0.7s;
//     }

//     .spinnering .rect6 {
//         -webkit-animation-delay: -0.6s;
//         animation-delay: -0.6s;
//     }

//     @-webkit-keyframes stretchdelay {

//         0%,
//         40%,
//         100% {
//             -webkit-transform: scaleY(0.4);
//         }

//         20% {
//             -webkit-transform: scaleY(1);
//         }
//     }

//     @keyframes stretchdelay {

//         0%,
//         40%,
//         100% {
//             transform: scaleY(0.4);
//             -webkit-transform: scaleY(0.4);
//         }

//         20% {
//             transform: scaleY(1);
//             -webkit-transform: scaleY(1);
//         }
//     }

// }
</style>



import request from "../index"



export function login(data) {
    return request({
        url: "/login",
        method: "POST",
        data,

    })
}
export function getFriendList(data) {
    return request({
        url: "/friend/list",
        method: "POST",
        data,

    })
}
export function getConversation(data) {
    return request({
        url: "/conversation/sync",
        method: "POST",
        data,

    })
}
export function getMessageList(data) {
    return request({
        url: "/message/channelMessageSync",
        method: "POST",
        data,

    })
}
export function getLanguageList(data) {
    return request({
        url: "/message/languageList",
        method: "POST",
        data,

    })
}
export function translation(data) {
    return request({
        url: "/message/translation",
        method: "POST",
        data,

    })
}
export function editRemark(data) {
    return request({
        url: "/friend/setFriendRemark",
        method: "POST",
        data,

    })
}
export function updateFriendInfo(data) {
    return request({
        url: "/friend/chatInfo",
        method: "POST",
        data,

    })
}

// 读取所有快捷回复
export function getQuickReply(data) {
    return request({
        url: "/quickReply/list",
        method: "POST",
        data,
    })
}
//
export function removeQuickReply(data) {
    return request({
        url: "/quickReply/remove",
        method: "POST",
        data,
    })
}
export function addText(data) {
    return request({
        url: "/quickReply/saveText",
        method: "POST",
        data,
    })
}
export function addImage(data) {
    return request({
        url: "/quickReply/saveImage",
        method: "POST",
        data,
    })
}
export function addVoice(data) {
    return request({
        url: "/quickReply/saveVoice",
        method: "POST",
        data,
    })
}
export function addVideo(data) {
    return request({
        url: "/quickReply/saveVideo",
        method: "POST",
        data,
    })
}
export function addCard(data) {
    return request({
        url: "/quickReply/saveCard",
        method: "POST",
        data,
    })
}
export function setUnread(data) {
    return request({
        url: "/conversation/setUnread",
        method: "POST",
        data,
    })
}

// 账号列表下拉
export function accountAccountList(data) {
    return request({
        url: "/account/accountList",
        method: "POST",
        data,
    })
}

// 好友转移
export function friendMove(data) {
    return request({
        url: "/friend/move",
        method: "POST",
        data,
    })
}

// 导入好友
export function friendAdd(data) {
    return request({
        url: "/friend/add",
        method: "POST",
        data,
    })
}

// 账号统计
export function accountReport(data) {
    return request({
        url: "/account/report",
        method: "POST",
        data,
    })
}

// 获取下拉选项
export function accountLinkedData(data) {
    return request({
        url: "/account/linkedData",
        method: "POST",
        data,
    })
}

// 注册账号
export function accountRegister(data) {
    return request({
        url: "/account/register",
        method: "POST",
        data,
        notIntercept:true,//指定不拦截,将失败的信息也返回
    })
}
export function accountScanWeb(data) {
    return request({
        url: "/account/scanWeb",
        method: "POST",
        data,
        notIntercept:true,//指定不拦截,将失败的信息也返回
    })
}
export function accountIsScanSuccess(data) {
    return request({
        url: "/account/isScanSuccess",
        method: "POST",
        data,
        notIntercept:true,//指定不拦截,将失败的信息也返回
    })
}


// 注册账号
export function editAccountInfo(data) {
    return request({
        url: "/account/editAccountInfo",
        method: "POST",
        data,
        notIntercept:true,//指定不拦截,将失败的信息也返回
    })
}

export function getAccountInfo(data) {
    return request({
        url: "/account/getAccountInfo",
        method: "POST",
        data,
        notIntercept:true,//指定不拦截,将失败的信息也返回
    })
}
// 删除会话
export function conversationDelete(data) {
    return request({
        url: "/conversation/delete",
        method: "POST",
        data,
    })
}

// 删除账号
export function accountRemove(data) {
    return request({
        url: "/account/remove",
        method: "POST",
        data,
    })
}

// 删除好友
export function friendRemove(data) {
    return request({
        url: "/friend/remove",
        method: "POST",
        data,
    })
}

// 发送消息
export function messageSend(data) {
    return request({
        url: "/message/send",
        method: "POST",
        data,
    })
}

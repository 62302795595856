<template>

    <el-dialog :close-on-click-modal="false" title="账号编辑" :visible.sync="showEditAccountInfo" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item  prop="nickName" label="账号昵称">
          <el-input v-model="form.nickName" placeholder="输入账号昵称,不能为空"></el-input>
          <button @click.prevent="onSubmit('nickname')" class="code-btn" :disabled="loading" v-loading="loading">
            修改昵称
          </button>
        </el-form-item>

        <el-form-item prop="staticIp" label="当前IP">
          <el-input :readonly="true" v-model="form.staticIp" placeholder="格式(地址:端口:账号:密码) 为空则使用系统分配,否则绑定所填写的IP"></el-input>
          <button @click.prevent="onSubmit('staticIp')" class="code-btn" :disabled="loading" v-loading="loading">
              一键换IP
          </button>

        </el-form-item>


        <el-form-item label="头像图片" prop="content" align="left">
          <el-upload :action="`${baseURL()}/quickReply/upload`" :headers="headers" :on-success="imageSuccess"
                     :file-list="imageList" list-type="picture" :limit="1">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">图片尺寸需要大于100 * 100像素，支持jpg、png、jpeg等格式，大小不能超过2MB</div>
          </el-upload>
          <button @click.prevent="onSubmit('avatar')" class="code-btn" :disabled="loading" v-loading="loading">
            更换头像
          </button>
        </el-form-item>
      </el-form>
    </el-dialog>


</template>
<script>
import {accountLinkedData, accountRegister, editAccountInfo, getAccountInfo} from "@/api/request/api.js"
import { MessageBox } from "element-ui";
import {baseURL} from "@/api/config"; // 引入MessageBox
export default {
  components: {

  },
  data() {
    return {
      showEditAccountInfo:false,
      imageList:[],
      headers: {
        Authorization: localStorage.getItem("token")
      },
      form: {
        setType:"",
        staticIp:"",
        accountId:"",
        avatarPath:"",
        nickName:'',
      },
      rules: {},
      checked: false,
      loading: false,
      downList: {
        accountGroup: [],
        countryList: [],
        proxyGroup: [],
        staticIpList: []
      },
      show: true,
      count: 0
    }
  },
  mounted() {
    this.getDownList()
  },
  methods: {
    baseURL() {
      return baseURL
    },
    // 获取下拉数据
    getDownList() {
      accountLinkedData().then(res => {
        if (res && res.code === 200) {
          this.downList = res.data
          this.$nextTick(()=>{
            if (this.downList.accountGroup.length < 1){
              this.showErrorDialog("由于版本迭代升级,请联系管理重新为你分配账户")
              return
            }
            this.form.toGroupId = this.downList.accountGroup[0].id;
            this.form.countryId = 200;
          })
        }
      })
    },
    async onSubmit(setType) {
      this.loading = true
      this.form.setType = setType
      const result = await editAccountInfo(this.form)
      this.loading = false
      this.editAccountInfoShow(this.form.phoneNumber)
      if (result && result.code === 200) {
        this.showSuccessDialog(result.message)
        this.$emit('registerEnd')
      } else {
        this.showErrorDialog(result.message)
      }
    },
    // 获取短信验证码
    async getCode() {
      this.form.smsCode = ''
      this.loading = true
      const result = await accountRegister(this.form)
      this.loading = false
      if (result && result.code === 200) {
        this.showSuccessDialog(result.message)
      } else {
        this.showErrorDialog(result.message)
      }
    },

    // 弹窗提示成功信息
    showSuccessDialog(message) {
      MessageBox.alert(message, '成功', {
        confirmButtonText: '确定',
        type: 'success'
      });
    },

    // 弹窗提示错误信息
    showErrorDialog(message) {
      MessageBox.alert(message, '错误', {
        confirmButtonText: '确定',
        type: 'error'
      });
    },

    imageSuccess(result) {
      if (result.code === 200) {
        this.form.avatarPath = result.data.path
      }
    },

    editAccountInfoShow(phoneNumber){
      getAccountInfo({phoneNumber}).then(res=>{
        if (res.code !== 200){
          this.showErrorDialog(res.message)
          return
        }
        this.form.staticIp = res.data.staticIp
        this.form.nickName=res.data.nickname
        this.form.phoneNumber = phoneNumber
        this.showEditAccountInfo = true
      })
    },

    // 返回上一页
    back() {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss">
.login-contrain {
  // background-color: #fafafa;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .warp {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    display: flex;
    justify-content: center;
  }

  .login-from {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 700px;
    padding: 40px;
    background-color: #fff;

    .login-title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .cn {
        display: 28px;
        font-weight: bold;
        color: #333;
      }

      .en {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}

/* 短信验证码css */
.pr {
  position: relative;
}

.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: #409EFF;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}

</style>
